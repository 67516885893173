














































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { RejectResponse } from 'common-modules/src/store/moduleStore';
import { ProgrammeFinish, Student } from '@/views/programme/types/ProgrammeFinish';
import ProgrammeFinishStudent from '@/views/programme/components/ProgrammeFinishStudent.vue';
import JwlButton from '@/components/JwlButton.vue';
import BackBar from '@/components/BackBar.vue';

const CommonError = () => import('common-modules/src/components/CommonError.vue');

@Component({
  components: {
    BackBar,
    CommonError,
    ProgrammeFinishStudent,
    JwlButton,
  },
})
export default class JwlProgrammeFinish extends Vue {
  programme: ProgrammeFinish | null = null;
  downloadUrl: string | null = null;
  error: RejectResponse | null = null;
  loading = true;
  loadingStudents = true;
  finishStudentIndex = -1;
  finalizing = false;

  fetchData (fetchStudents = true): void {
    this.$store.dispatch('getData', `programme-finalize/${this.$route.params.programme}`)
      .then((data) => {
        this.programme = data.programme;
        this.downloadUrl = data.download;
        this.loading = false;
        if (fetchStudents) {
          this.fetchStudentData(0)
            .then(() => {
              this.loadingStudents = false;
            });
        }
      })
      .catch((e) => {
        this.error = e;
      });
  }

  fetchStudentData (index: number): Promise<void> {
    return new Promise<void>((resolve) => {
      if (this.programme) {
        const student = this.programme.students[index];
        this.$store.dispatch('getData', `programme-finalize/${this.programme.id}/${student.applicantId}`)
          .then((data) => {
            Object.assign(student, data);
            this.handleStudentData(data);
          })
          .finally(() => {
            if (this.programme && (this.programme.students.length - 1) > index) {
              const nextIndex = index + 1;
              this.fetchStudentData(nextIndex)
                .finally(() => {
                  resolve();
                });
            } else {
              resolve();
            }
          });
      } else {
        resolve();
      }
    });
  }

  handleStudentData (data: any): void {
    if (this.programme) {
      this.programme.maxScore = data.maxScore;
      this.programme.maxAttendance = data.maxAttendance;
      if (data.workNotGraded.length > 0) {
        this.programme.issues.workNotGraded = [
          ...this.programme.issues.workNotGraded,
          ...data.workNotGraded,
        ];
      }
      if (data.gradeNotPublished.length > 0) {
        this.programme.issues.gradeNotPublished = [
          ...this.programme.issues.gradeNotPublished,
          ...data.gradeNotPublished,
        ];
      }
      if (data.studentNotPublished.length > 0) {
        this.programme.issues.studentNotPublished = [
          ...this.programme.issues.studentNotPublished,
          ...data.studentNotPublished,
        ];
      }
    }
  }

  finalizeProgramme (): void {
    this.finishStudentIndex = 0;
    this.finalizing = true;
    this.finishStudent(0)
      .then(() => {
        this.finishFinalizeProgramme();
      });
  }

  finishStudent (index: number): Promise<void> {
    return new Promise<void>((resolve) => {
      if (this.programme) {
        const student = this.programme.students[index];
        this.$store.dispatch('postEmptyData', `programme-finalize/${this.programme.id}/${student.applicantId}`)
          .then((data) => {
            if (this.programme) {
              Object.assign(student, data);
            }
          })
          .finally(() => {
            if (this.programme && (this.programme.students.length - 1) > index) {
              this.finishStudentIndex += 1;
              this.finishStudent(this.finishStudentIndex)
                .finally(() => {
                  resolve();
                });
            } else {
              resolve();
            }
          });
      } else {
        resolve();
      }
    });
  }

  updateStudentData (index: number, data: Student): void {
    if (this.programme) {
      const student = this.programme.students[index];
      Object.assign(student, data);
    }
  }

  updateStudentStatus (index: number, data: string): void {
    if (this.programme) {
      const student = this.programme.students[index];
      student.status = data;
    }
  }

  finishFinalizeProgramme (): void {
    this.$store.dispatch('postEmptyData', `programme-finalize/${this.programme?.id}`)
      .then(() => {
        this.finishStudentIndex = -1;
        this.finalizing = false;
        this.fetchData(false);
      })
      .catch((e) => {
        this.error = e;
      });
  }

  downloadCertificates (): void {
    this.$store.dispatch('getData', `programme-certificate/${this.programme?.id}`)
      .then((data) => {
        if (data.url) {
          window.location.assign(data.url);
        }
      });
  }

  formattedPercent (numerator: number, denominator: number): string {
    if (denominator > 0) {
      const percent = (numerator / denominator) * 100;
      return `${percent.toFixed(1)}%`;
    }

    return '0%';
  }

  get hasIssues (): boolean {
    if (!this.programme) {
      return true;
    }

    const { issues } = this.programme;
    return issues.studentNotPublished.length > 0
      || issues.workNotGraded.length > 0
      || issues.gradeNotPublished.length > 0;
  }

  get showFinalizeButton (): boolean {
    if (!this.programme) {
      return false;
    }

    const { status } = this.programme;
    if (status === 'finished') {
      return false;
    }

    if (this.hasIssues) {
      return this.programme.role === 'superAdmin';
    }

    return true;
  }

  mounted (): void {
    this.fetchData();
  }
}
