























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import JwlButton from '@/components/JwlButton.vue';
import { ProgrammeFinish, Student } from '@/views/programme/types/ProgrammeFinish';
import JwlSubmitRow from '@/components/JwlSubmitRow.vue';

@Component({
  components: {
    JwlSubmitRow,
    JwlButton,
  },
})
export default class ProgrammeFinishStudent extends Vue {
  @Prop(Object)
  jwlStudent!: Student;

  @Prop(Object)
  jwlProgramme!: ProgrammeFinish;

  syncStatus = 0;
  error = null;

  finalizeStudent (): void {
    if (this.syncStatus !== 1) {
      this.syncStatus = 1;
      this.$store.dispatch('postEmptyData', `programme-finalize/${this.jwlProgramme.id}/${this.jwlStudent.applicantId}`)
        .then((data) => {
          this.$emit('student-finalized', data);
          this.syncStatus = 2;
        })
        .catch((e) => {
          this.error = e;
          this.syncStatus = -1;
        });
    }
  }

  failStudent (): void {
    if (!this.showFailButton || this.syncStatus === 1) {
      return;
    }
    this.syncStatus = 1;
    this.$store.dispatch('postEmptyData', `programme-finalize/${this.jwlProgramme.id}/${this.jwlStudent.applicantId}/fail-programme`)
      .then(() => {
        this.$emit('student-status-updated', 'failed');
        this.syncStatus = 2;
        setTimeout(() => {
          if (this.syncStatus === 2) {
            this.syncStatus = 0;
          }
        }, 2500);
      })
      .catch((e) => {
        this.error = e;
        this.syncStatus = -1;
      });
  }

  unfailStudent (): void {
    if (!this.showUnfailButton || this.syncStatus === 1) {
      return;
    }
    this.syncStatus = 1;
    this.$store.dispatch('postEmptyData', `programme-finalize/${this.jwlProgramme.id}/${this.jwlStudent.applicantId}/unfail-programme`)
      .then(() => {
        this.$emit('student-status-updated', 'completed');
        this.syncStatus = 2;
        setTimeout(() => {
          if (this.syncStatus === 2) {
            this.syncStatus = 0;
          }
        }, 2500);
      })
      .catch((e) => {
        this.error = e;
        this.syncStatus = -1;
      });
  }

  get showFinalizeStudentButton (): boolean {
    return this.jwlStudent.status === 'active';
  }

  get showFailButton (): boolean {
    return this.jwlStudent.score != null
      && this.jwlStudent.status === 'completed';
  }

  get showUnfailButton (): boolean {
    return this.jwlStudent.score != null
      && this.jwlStudent.status === 'failed';
  }
}
